import React from "react";
import "./AnalogClock.css";
import { useState } from 'react';
import { useEffect } from 'react';
function AnalogClock() {
    const [time , setTime] = useState("")

    useEffect(() => {
        setTimeout(() => {
            const date = new Date().toLocaleString();
            const time=date.split(",")
            setTime(time[1])
        }, 1000);
      });
  return (
    <div className="clock__circle">
        {time}
    </div>
  );
}

export default AnalogClock;
