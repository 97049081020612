import { Add } from "@mui/icons-material";
import {
  Box,
  Button,
  Fab,
  FormControlLabel,
  FormHelperText,
  Grid,
  Link,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import React, { useEffect } from "react";
import { useState } from "react";
import DialogBox from "../../components/Dialog";
import AnalogClock from "./AnalogClock/AnalogClock";
import Floatbutton from "./FloatingButton/floatbutton";
import Onoff from "./Onoff";
import "./timer.css";
import SnackToast from "../../components/Snackbar";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import * as dayjs from "dayjs";
import { DateTimePicker } from "@mui/x-date-pickers";
import { DeleteMethod, getMethod, postMethod } from "../../api/methods";
import { apikey, endpoints } from "../../api/endpoints";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import utc from 'dayjs/plugin/utc';
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

function Timer(props) {
  const [open, setOpen] = useState(false);
  const [, setValue] = useState(
    "Sat Mar 04 2023 15:14:00 GMT+0530 (India Standard Time)"
  );
  const [date, setDate] = useState(dayjs());
  const [onoff, setOnoff] = useState();
  const [toast, setToast] = useState({
    open: false,
    message: "",
    severity: "",
  });
  const { deviceid } = useParams();
  const [timererror, setTimerError] = useState(false);

  // Getapi
  const { data, isLoading, refetch } = useQuery(
    ["getschedule"],
    async () => {
      const data = await getMethod(
        endpoints.getSchedule(deviceid),
        apikey.getSchedule
      );
      return data.data;
    }
  );

  const handlechange = (e, newvalue) => {
    setValue(e.toDate());
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
  };

  const handleSubmit = async () => {
    if (!onoff) {
      setTimerError(true);
      return;
    }
    try {
      const addtime = await postMethod(
        endpoints.addSchedule,
        apikey.addSchedule,
        {
          device_id: deviceid,
          command: onoff,
          schedule: date.utc().format("YYYY-MM-DD HH:mm:ss"),
        }
      );
      console.log({
        device_id: deviceid,
        command: onoff,
        schedule: date.toDate().toISOString(),
        status: "pending",
      });
      console.log(date.toDate());
      refetch();
      setToast({ open: true, message: "Success", severity: "success" });
    } catch (err) {
      console.log(err);
    }
    setOpen(false);
  };
  const handlecloseToast = () => {
    setToast({ open: false, severity: "", message: "" });
  };

  const deleteTimer = async (data) => {
    try {
      const deleteTimer = await DeleteMethod(
        endpoints.deleteTimer(data.device_id, data.schedule),
        apikey.deleteTimer
      );
      setToast({
        open: true,
        message: "Deleted Successfully",
        severity: "success",
      });
      console.log(deleteTimer);
      refetch();
    } catch (error) {
      console.log(error);
    }
    setOpen(false);
  };

  return (
    <div className="timer_container">
      <AnalogClock />
      <br />
      <br />
      <Stack spacing={2}>
        {isLoading ? (
          <>Loading...</>
        ) : (
          data.sort((a, b) => {
            return dayjs(a.schedule).diff(dayjs()) > dayjs(b.schedule).diff(dayjs()) ? 1 : -1;
          }).map((data, ind) => (
            <React.Fragment key={ind}>
              <Onoff
                value={data.schedule}
                handlechange={handlechange}
                data={data}
                deleteTimer={deleteTimer}
              />
            </React.Fragment>
          ))
        )}
      </Stack>
      {!isLoading && data?.length < 1 && <Typography>No data...</Typography>}

      <Box sx={{ "& > :not(style)": { m: 1 } }} className="floating_button">
        <Fab
          aria-label="add"
          color="primary"
          onClick={handleClickOpen}
          disabled={data?.length >= 10}
        >
          <Add />
        </Fab>
      </Box>

      <DialogBox
        open={open}
        title="Add Timer"
        handleClose={handleClose}
        component={
          <AddTimer
            date={date}
            setDate={setDate}
            onOff={onoff}
            setOnoff={setOnoff}
            timererror={timererror}
            setTimerError={setTimerError}
          />
        }
        actioncomponent={
          <>
            <Button onClick={handleClose}>cancel</Button>{" "}
            <Button disableElevation onClick={handleSubmit}>
              ok
            </Button>
          </>
        }
      />

      <SnackToast
        open={toast.open}
        message={toast.message}
        severity={toast.severity}
        handleClose={handlecloseToast}
      />
    </div>
  );
}

export default Timer;

const AddTimer = ({
  date,
  setDate,
  onOff,
  setOnoff,
  timererror,
  setTimerError,
}) => {
  useEffect(() => {
    return () => {
      setTimerError(false);
      setOnoff("");
    };
  }, []);

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateTimePicker
          disablePast={true}
          sx={{
            "&": {
              backgroundColor: "black",
            },
          }}
          renderInput={(props) => <TextField {...props} fullWidth />}
          value={date}
          onChange={(e) => {
            setDate(e);
          }}
        />
      </LocalizationProvider>
      <br />
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        name="onoff"
        row
        value={onOff}
        onClick={(e) => setOnoff(e.target.value)}
      >
        <FormControlLabel value="on" control={<Radio />} label="On" />
        <FormControlLabel value="off" control={<Radio />} label="Off" />
      </RadioGroup>
      {timererror && !onOff && (
        <FormHelperText error={true}>Select On/Off</FormHelperText>
      )}
    </>
  );
};
