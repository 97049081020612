import { generateTimers } from "./utils"
import { DeleteMethod, getMethod, postMethod } from "../../api/methods";
import { apikey, endpoints } from "../../api/endpoints";
import LoadingSwitch from "../../components/LoadingSwitch";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useMemo } from "react";

export function AutomationSwitch({ field, id, row, refetchTimers }) {
	const [isAutomated, setIsAutomated] = useState(false)
	const { data, isLoading, refetch } = useQuery(
		["getschedule", id],
		async () => {
			const response = await getMethod(
				endpoints.getSchedule(id),
				apikey.getSchedule
			);
			const filteredResponse = response.data.filter(({ automation_type }) => typeof automation_type === "string")
			return filteredResponse
		}
	);
	const [loading, setLoading] = useState(false)

	const handleChange = async (event) => {
		const { checked } = event.target
		setLoading(true)
		if (checked === true) {
			const timers = generateTimers(field, id)
			await postMethod(
				endpoints.addSchedule,
				apikey.addSchedule,
				{timers}
			);
			setIsAutomated(true)
			refetchTimers()
		} else {
			await DeleteMethod(
				endpoints.deleteTimer(id, undefined, field),
				apikey.deleteTimer
			);
			setIsAutomated(false)
			refetchTimers()
		}
		refetch()
		setLoading(false)
	}

	const isDisabled = useMemo(() => {
		let output = false
		if (row.status === "unplugged") {
			output = true
		} else {
			if (isAutomated === true) {
				output = false
			} else if (Array.isArray(data) && data.length > 0) {
				output = true
			}
		}
		return output
	}, [data, isAutomated, row.status])

	useEffect(() => {
		if (Array.isArray(data)) {
			const isAutomated = data
				// Check if any of the schedule is set by any automation
				.some((ele) => ele.automation_type === field)
			setIsAutomated(isAutomated)
		}
	}, [data, field, id])

	return <LoadingSwitch
		loading={(loading || isLoading)}
		onChange={handleChange}
		checked={isAutomated}
		disabled={isDisabled}
	/>
}
