import { Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { apikey, endpoints } from "../../api/endpoints";
import { getMethod } from "../../api/methods";
import { optionbarchart } from "./utilities";
import { co2option } from "./utilities";
import { useDispatch, useSelector } from "react-redux";

// chart js
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { Stack } from "@mui/system";
import { CallEnd, CallMade, CallReceived } from "@mui/icons-material";
import EChartsReact from "echarts-for-react";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  // Title,
  Tooltip,
  Legend
);

const mockdata = {
  timestamp: [12, 22, 21, 21, 22],
  value: [18, 36, 20, 32,11,20],
};
const mockdata_energy = {
  timestamp : [12, 22, 21, 21, 22],
  energy_savings : [1,5,8,2,6],
  co2 : [10,11,6,8,4]
}
function ConsumptionRight() {
  // const [energysavingsadata, setEnergysavingsdata] = useState({
  //   co2_reduction: 0,
  //   energy_savings: 0,
  // });

  // useEffect(() =>{
  //   const interval = setInterval(()=>{
  //     setBarchart(mockdata)
  //   });

  // },10)
  const userid = useSelector(
    (state) => state.dashboardreducer.consumption.userid
  );
  const [barchart, setBarchart] = useState([]);
  const [energysavingsadata, setEnergysavingsdata] = useState([]);
  // useEffect(() =>{
  //   const intervals = setInterval(()=>{
  //     setBarchart(mockdata)
  //   });

  // },10)
  useEffect(() => {
    const interval = setInterval(()=>{
      setBarchart(mockdata)
      setEnergysavingsdata(mockdata_energy)
    },10)
    const energysavingsapi = async () => {
      const data = await getMethod(
        endpoints.energysavings(userid),
        apikey.energysavings
      );
      console.log(data);
      
      setEnergysavingsdata(data.data);
      console.log(energysavingsadata);
    };
    const Monthly_consumption = async () => {
      const data = await getMethod(
        endpoints.barchart(userid, "monthly"),
        apikey.barchart
      );
      clearInterval(interval);
      setBarchart(data.data.body);
      // setBarchart(mockdata);
    };

    const time = setInterval(() => {
      Monthly_consumption();
      energysavingsapi();
    }, 2000);

    return () => {clearInterval(time);
                  clearInterval(interval);}
  }, [userid]);

  return (
    <div>
      {/* <ConsumptionCo2reduction
        value={12.34}
        name={"Energy Savings"}
        unit={"KWh"}
        up={true}
      />
      <ConsumptionCo2reduction
        value={12.34}
        name={"Co2 Reduction"}
        unit={"gr"}
        up={false}
      /> */}
      <div className="container_with_shadow ">
        <Typography>
          <b>Energy Savings & CO2 Reduction</b>
        </Typography>
        {/* <div>
          <span>ES</span> <span>Co2</span>
        </div> */}
        <EChartsReact
          style={{ height: "230px" }}
          option={co2option(
            energysavingsadata?.timestamp ?? [],
            energysavingsadata?.energy_savings ?? [],
            energysavingsadata?.co2 ?? [],
          )}
        />
      </div>
      {/* <Grid container>
          <Grid item md={6} xs={12} sm={12} lg={6}>
            <div className="gauge_consumotionpage">
              <EChartsReact
                key={energysavingsadata?.energy_savings}
                option={carbonrigt(
                  energysavingsadata?.energy_savings ?? 0,
                  "kWh"
                )}
              />
            </div>
            <Typography sx={{ textAlign: "center" }}>
              <b>Energy Savings</b>
            </Typography>
          </Grid>

          <Grid item md={6} sm={12} xs={12} lg={6}>
            <div className="gauge_consumotionpage">
              <EChartsReact
                key={energysavingsadata?.co2_reduction}
                option={carbonrigt(
                  energysavingsadata?.co2_reduction ?? 0,
                  "gr"
                )}
              />
            </div>
            <Typography sx={{ textAlign: "center" }}>
              <b>Co2 Reduction</b>
            </Typography>
          </Grid>
        </Grid> */}
      <div className="container_with_shadow line_chart_mainmenu">
        <Typography>
          <b>Monthly Consumption</b>
        </Typography>

        {/* <Bar
          option={
            optionbarchart(barchart?.timestamp ?? [], barchart?.value ?? [])
              .options
          }
          data={
            optionbarchart(barchart?.timestamp ?? [], barchart?.value ?? [])
              .data
          }
        /> */}

        <EChartsReact
          option={optionbarchart(
            barchart?.timestamp ?? [],
            barchart?.value ?? [],
            ["#bdcf32", "#87bc45", "#27aeef", "#b33dc6"]
          )}
        />
      </div>
    </div>
  );
}

export default ConsumptionRight;

const ConsumptionCo2reduction = ({ value, name, unit, up }) => {
  return (
    <div className="container_with_shadow">
      <Stack
        padding={2}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack spacing={0.3}>
          <Typography variant="h5" className="span_text_container">
            {value}{" "}
            <span className={up ? "span_text" : "span_text_error"}>{unit}</span>
          </Typography>
          <Typography variant="caption" className="text_muted">
            {name}
          </Typography>
        </Stack>

        {up ? (
          <div className="energy_up">
            <CallMade />
          </div>
        ) : (
          <div className="energy_down">
            <CallReceived />
          </div>
        )}
      </Stack>
    </div>
  );
};
