import { DataGrid } from "@mui/x-data-grid";
import { useCallback, useEffect, useMemo, useState } from "react"
import { makeStyles } from "@mui/styles";
import "./lastmile.css"
import { useDispatch, useSelector } from "react-redux";
import { getMethod } from "../../api/methods";
import { apikey, endpoints } from "../../api/endpoints";
import {
  setTotaldevices,
  setDevice,
} from "../../redux/dashboardslice";
import { AutomationSwitch } from "./AutomationSwitch";
import { Grid, Typography } from "@mui/material";
import Chart from "react-apexcharts";
import { options2 } from "../consumption/utilities";
import { useQuery } from "react-query";
import dayjs from "dayjs";

const AutomationColumns = (refetch) => ([
  {
    field: "devicename",
    headerName: "Device",
    minWidth: 150,
    flex: 1,
    headerAlign: "center",
    justifyContent: "space-between",
    headerClassName: "datagrid_header",
    sortable: false,
    // TODO: Add input field if needed
  },
  {
    field: "automation-1",
    headerName: "Automation 1",
    type: "actions",
    minWidth: 150,
    flex: 1,
    headerClassName: "datagrid_header",
    sortable: false,
    renderCell: (props) => {
      return <AutomationSwitch {...props} refetchTimers={refetch} />
    }
  },
  {
    field: "automation-2",
    headerName: "Automation 2",
    type: "actions",
    minWidth: 150,
    flex: 1,
    headerClassName: "datagrid_header",
    sortable: false,
    renderCell: (props) => {
      return <AutomationSwitch {...props} refetchTimers={refetch} />
    }
  },
  {
    field: "automation-3",
    headerName: "Automation 3",
    type: "actions",
    minWidth: 150,
    flex: 1,
    headerClassName: "datagrid_header",
    sortable: false,
    renderCell: (props) => {
      return <AutomationSwitch {...props} refetchTimers={refetch} />
    }
  },
  {
    field: "automation-4",
    headerName: "Automation 4",
    type: "actions",
    minWidth: 150,
    flex: 1,
    headerClassName: "datagrid_header",
    sortable: false,
    renderCell: (props) => {
      return <AutomationSwitch {...props} refetchTimers={refetch} />
    }
  }
]);

const IncentivesColumns = [
  {
    field: "date",
    headerName: "Date",
    minWidth: 150,
    type: "date",
    flex: 1,
    headerAlign: "center",
    justifyContent: "space-between",
    headerClassName: "datagrid_header",
    sortable: false,
  },
  {
    field: "amount",
    headerName: "Amount (USD)",
    minWidth: 150,
    headerAlign: "center",
    flex: 1,
    align: "center",
    headerClassName: "datagrid_header",
    sortable: false,
  }
];
const EventsColumns = [
  {
    field: "event",
    headerName: "Event",
    minWidth: 150,
    flex: 1,
    headerAlign: "center",
    justifyContent: "space-between",
    headerClassName: "datagrid_header",
    sortable: false,
  },
  {
    field: "id",
    headerName: "Device",
    minWidth: 150,
    headerAlign: "center",
    flex: 1,
    align: "center",
    headerClassName: "datagrid_header",
    sortable: false,
  },
  {
    field: "time",
    headerName: "Time",
    minWidth: 150,
    headerAlign: "center",
    flex: 1,
    align: "center",
    headerClassName: "datagrid_header",
    sortable: false,
  },
  {
    field: "date",
    headerName: "Date",
    minWidth: 150,
    type: "date",
    flex: 1,
    headerAlign: "center",
    justifyContent: "space-between",
    headerClassName: "datagrid_header",
    sortable: false,
  },
];
const useStyles = makeStyles({
  root: {
    "&.MuiDataGrid-root .MuiDataGrid-cell:focus": {
      outline: "none",
    },
  },
});

const MAX_EVENTS = 96

function LastMile() {
  const userid = useSelector(
    (state) => state.dashboardreducer.consumption.userid
  );
  const dispatch = useDispatch();
  const { data: telemetryData, isLoading: isTelemetryLoading, refetch } = useQuery(
    ["getTelemetry", userid],
    async () => {
      const data = await getMethod(
        endpoints.SmartPlugLatestTelemetryAPI(userid),
        apikey.SmartPlugLatestTelemetryAPI
      );
      return data.data;
    }
  );
  const { data: incentivesData, isLoading: isIncentivesLoading } = useQuery(
    ["incentives"],
    async () => {
      const data = await getMethod(
        endpoints.getIncentives,
        apikey.getIncentives
      );
      return data.data;
    }
  );
  const { data: expiredEvents, isLoading: isExpiredEventsLoading } = useQuery(
    ["expiredEvents", userid],
    async () => {
      const response = await getMethod(
        endpoints.getSchedule(undefined, userid, true),
        apikey.getSchedule
      );
      const { data } = response
      const formattedData = data
      .sort((a, b) => dayjs.utc(a.schedule) - dayjs.utc(b.schedule))
      .map(({ command, device_id, schedule }) => {
        const date = dayjs.utc(schedule).local()
        return ({
          event: command,
          id: device_id,
          time: date.format('HH:mm:ss'), // Ref: https://day.js.org/docs/en/display/format
          date: date.format("YYYY-MM-DD")
        })
      })
      return formattedData
    }
  );

  const [deviceData, setDeviceData] = useState([])
  const [eventsData, setEventsData] = useState([])
  const classes = useStyles();

  const getEventsData = useCallback(async () => {
    const outputPromises = deviceData.map(async ({ devicename }) => {
      const data = await getMethod(
        endpoints.getSchedule(devicename),
        apikey.getSchedule
      );
      return data.data
    })
    const output = await Promise.all(outputPromises)
    const deviceDictionary = output.flat()
      .filter(({ automation_type }) => typeof automation_type === "string")
      .reduce((acc, curr) => {
        let output = { ...acc }
        if (acc[curr.device_id] === undefined) {
          output[curr.device_id] = 1
        } else {
          output[curr.device_id] = acc[curr.device_id] + 1
        }
        return output
      }, {})
    setEventsData(deviceDictionary)
  }, [deviceData])

  const gettable = useCallback(async () => {
    try {
      let datarr = Object.keys(telemetryData.body).map((key) => {
        return { devicename: key, ...telemetryData.body[key] };
      });
      dispatch(setTotaldevices(telemetryData.body.total_plugged));

      datarr = datarr.filter(
        (name) => !["total_plugged", "total"].includes(name.devicename)
      ).map(({ devicename, status }) => ({
        devicename,
        status
      }));
      setDeviceData(datarr)
      dispatch(setDevice(telemetryData.body));
    } catch (error) {
      console.log(error);
    }
  }, [dispatch, telemetryData]);

  useEffect(() => {
    if (isTelemetryLoading === false) {
      gettable()
    }
  }, [isTelemetryLoading, gettable]);

  useEffect(() => {
    if (isTelemetryLoading === false) {
      getEventsData()
    }
  }, [getEventsData, isTelemetryLoading])

  return (
    <>
      <Grid container>
        <Grid item md={6} lg={6} sm={12} xs={12}>
          <Grid
            container
            maxWidth="calc(100% - 20px)"
            className="container_with_shadow"
            justifyContent="center"
            height="300px"
            overflow="auto"
          >
            <Grid item md={12} sm={12} lg={12} xs={12}>
              <Typography>
                <b>Devices Activated</b>
              </Typography>
            </Grid>
            {Object.entries(eventsData).map(([key, value]) => (
              <Grid item md={4} lg={4} sm={4} key={key}>
                <Chart
                  options={options2.options(key, MAX_EVENTS - value, "#1dd1a1")}
                  series={[((MAX_EVENTS - value) * 100) / MAX_EVENTS]}
                  type="radialBar"
                  height="220"
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item md={6} lg={6} sm={12} xs={12}>
          <div
            className="container_with_shadow"
            style={{
              height: "300px",
            }}
          >
            <Typography>
              <b>Event Details</b>
            </Typography>
            <DataGrid
              loading={isExpiredEventsLoading}
              sx={{ border: 0 }}
              className={classes.root}
              rows={Array.isArray(expiredEvents) ? expiredEvents : []}
              getRowId={() => crypto.randomUUID()}
              columns={EventsColumns}
              hideFooterSelectedRowCount
              disableColumnMenu
              pageSize={5}
              rowsPerPageOptions={[5]}
            />
          </div>
        </Grid>
        <Grid item md={6} lg={6} sm={12} xs={12}>
          <div
            className="container_with_shadow"
            style={{
              height: "300px",
            }}
          >
            <Typography>
              <b>Incentives</b>
            </Typography>
            <DataGrid
              isLoading={isIncentivesLoading}
              sx={{ border: 0 }}
              className={classes.root}
              rows={Array.isArray(incentivesData) ? incentivesData : []}
              getRowId={(row) => row.date}
              columns={IncentivesColumns}
              hideFooterPagination
              hideFooterSelectedRowCount
              hideFooter
              disableColumnMenu
              pageSize={5}
              rowsPerPageOptions={[5]}
            />
            {/* TODO: display total incentives here */}
          </div>
        </Grid>
        <Grid item md={6} lg={6} sm={12} xs={12}>
          <div
            className="container_with_shadow"
            style={{
              height: "300px",
            }}
          >
            <Typography>
              <b>Automation Status</b>
            </Typography>
            <DataGrid
              loading={isTelemetryLoading}
              sx={{ border: 0 }}
              className={classes.root}
              rows={deviceData}
              getRowId={(row) => row.devicename}
              columns={AutomationColumns(refetch)}
              hideFooterPagination
              hideFooterSelectedRowCount
              hideFooter
              disableColumnMenu
              pageSize={5}
              rowsPerPageOptions={[5]}
            />
          </div>
        </Grid>
      </Grid>
    </>
  )
}


export default LastMile;