import {
  Button,
  ButtonGroup,
  Grid,
  LinearProgress,
  TextField,
  Typography,
} from "@mui/material";

import { makeStyles } from "@mui/styles";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useRef, useState } from "react";
import LoadingSwitch from "../../components/LoadingSwitch";
import ConsumptionLeft from "./ConsumptionLeft";
import ConsumptionRight from "./ConsumptionRight";
import "./consumption.css";
import { useDispatch, useSelector } from "react-redux";
import {
  setDailyMonthy,
  setDataGridprice,
  setDataGridrow2,
  setSelectedRow,
  setTotaldevices,
  setTest,
} from "../../redux/dashboardslice";
import { getMethod } from "../../api/methods";
import { apikey, endpoints } from "../../api/endpoints";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import DialogBox from "../../components/Dialog";
import Energy from "../../assets/Energy.jpeg";

const useStyles = makeStyles({
  root: {
    "&.MuiDataGrid-root .MuiDataGrid-cell:focus": {
      outline: "none",
    },
  },
});

const color = [
  "#ea5545",
  "#f46a9b",
  "#ef9b20",
  "#edbf33",
  "#ede15b",
  "#bdcf32",
  "#87bc45",
  "#27aeef",
  "#b33dc6",
];

function Consumtionindex() {
  const dispatch = useDispatch();
  const rowclick = (e) => {
    dispatch(setSelectedRow(e.row));
  };
  const firstrender = useRef(true);
  // const rows = useSelector((state) => state.dashboardreducer.consumption.row);
  const { row: rows, dailyMonthly } = useSelector(
    (state) => state.dashboardreducer.consumption
  );
  const userid = useSelector(
    (state) => state.dashboardreducer.consumption.userid
  );
  const [open, setOpen] = useState(false);
  const headerImageOpen = () =>{
    setOpen(true);
  }
  const handleClose = () => {
    setOpen(false);
  };
  const classes = useStyles();

  const columns = [
    {
      field: "timestamp",
      headerName: "Date ",
      minWidth: 150,
      flex: 1,
      headerClassName: "datagrid_header",
      sortable: false
    },
    {
      field: "value",
      headerName: `${
        dailyMonthly == "daily" ? "Daily" : "Monthly"
      } Consumption (kWh)`,
      minWidth: 150,
      flex: 1,
      headerClassName: "datagrid_header",
      sortable: false
    },
    {
      field: "value1",
      headerName: `kWh`,
      minWidth: 150,
      flex: 1,
      headerClassName: "datagrid_header",
      sortable: false,
      renderCell: (props) => {
        return (
          <>
            <LinearProgress
              variant="determinate"
              value={
                dailyMonthly === "daily"
                  ? props.row.value * 10
                  : props.row.value
              }
              sx={{
                width: "200px",
                height: "15px",
                borderRadius: "3px",
                backgroundColor: "grey",
                "& .MuiLinearProgress-bar": {
                  bgcolor: color[props.api.getRowIndex(props.id)],
                  // bgcolor: "#000000",
                },
              }}
            />
          </>
        );
      },
    },
    {
      field: "power",
      // headerName: "COP/kWh",
      renderHeader: () => (
        <div className="header_icon">
        {"COP/kWh"}
        <ErrorOutlineIcon onClick={headerImageOpen} sx={{margin:"15px 10px"}}/>               
         </div>
      ),
      minWidth: 150,
      flex: 1,
      headerClassName: "datagrid_header",
      sortable: false,
      renderCell: (props) => {
        return <Price {...props} />;
      },
    },
    {
      field: "total",
      headerName: "Total Price (COP/kWh)",
      sortable: false,
      minWidth: 150,
      flex: 1,
      headerClassName: "datagrid_header",
    },
  ];

  const gettable = async () => {
    try {
      const data = await getMethod(
        endpoints.barchart(userid, dailyMonthly),
        apikey.barchart
      );

      console.log("table", data.data);

      // dispatch(setTotaldevices(data.data.body.total_plugged));
      // console.log(datarr);
      const datarr = [data.data.body];
      const dataArrtemp = data.data.body.timestamp.map((val, ind) => {
        return { timestamp: val, value: data.data.body.value[ind] };
      });

      dispatch(setDataGridrow2(dataArrtemp));
      if (firstrender.current) {
        firstrender.current = false;
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const api = setInterval(() => {
      gettable();
    }, 20000);
    gettable();

    return () => clearInterval(api);
  }, [dailyMonthly, userid]);
  return (
    <>
      <Grid container>
        <Grid item md={6} lg={6} sm={12} xs={12}>
          <ConsumptionLeft />
        </Grid>
        <Grid item md={6} lg={6} sm={12} xs={12}>
          <ConsumptionRight />
        </Grid>
      </Grid>

      <ButtonGroup sx={{ ml: 2 }}>
        <Button
          onClick={() => dispatch(setDailyMonthy("monthly"))}
          variant={dailyMonthly == "monthly" ? "contained" : "outlined"}
          disableElevation
        >
          Monthly
        </Button>
        <Button
          onClick={() => dispatch(setDailyMonthy("daily"))}
          variant={dailyMonthly == "daily" ? "contained" : "outlined"}
          disableElevation
        >
          Daily
        </Button>
      </ButtonGroup>

      <div className="container_with_shadow" style={{ height: "250px" }}>
        <DataGrid
          className={classes.root}
          rows={rows}
          columns={columns}
          getRowId={(row) => row?.devicename + row?.timestamp}
          // pageSize={5}
          rowsPerPageOptions={[5, 10, 15]}
          onRowClick={rowclick}
          sx={{ border: "none" }}
          hideFooterSelectedRowCount
          disableColumnMenu
          // disableColumnFilter={true}
          // disableColumnSort={true}
          
        />
      </div>
      <DialogBox
        open={open}
        handleClose={handleClose}        
        component={<img src={"https://plugattimage.s3.amazonaws.com/plugatt-energy-image.jpeg"} height="400px" width="550px" alt="image"/>}
      />
    </>
  );
}

const Price = (props) => {
  const dispatch = useDispatch();

  if (props.row.timestamp !== "NA") {
    return (
      <TextField
        type="number"
        width="30px"
        size="small"
        onChange={(e) => {
          console.log("props", props);
          dispatch(
            setDataGridprice({
              ind: props.api.getRowIndex(props.id),
              value: e.target.value,
              current: props.row.value,
            })
          );
        }}
        placeholder="price"
      />
    );
  } else {
    return <>NA</>;
  }
};

export default Consumtionindex;
