import { Grid, Typography } from "@mui/material";
import { width } from "@mui/system";
import EChartsReact from "echarts-for-react";
import React from "react";
import { color } from "../mainmenu/color";
import { optionbarchart, options2 } from "./utilities";
import ApexCharts from "apexcharts";
import Chart from "react-apexcharts";
import { useEffect } from "react";
import { getMethod } from "../../api/methods";
import { apikey, endpoints } from "../../api/endpoints";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setTest } from "../../redux/dashboardslice";
import "./consumption.css";
import dayjs from "dayjs";

// chart js
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  // Title,
  Tooltip,
  Legend
);

const mockdata = {
  timestamp: [12, 22, 21, 211, 22],
  value: [1, 23, 12, 23, 21, 10],
};

function ConsumptionLeft() {
  const [threeconsuption, setThreeConsumtion] = useState();

  const userid = useSelector(
    (state) => state.dashboardreducer.consumption.userid
  );
  const [barchart, setBarchart] = useState([]);
  useEffect(() => {
    const interval = setInterval(() => {
      setThreeConsumtion({
        historical_energy: Math.random().toFixed(2),
        monthly_energy: Math.random().toFixed(2),
        daily_energy: Math.random().toFixed(2),
      });
      setBarchart(mockdata);
    }, 10);

    const api = async () => {
      const data = await getMethod(
        endpoints.threeconsuption(userid),
        apikey.threeconsuption
      );
      clearInterval(interval);
      setThreeConsumtion(data.data.body);
    };
    const Daily_consumption = async () => {
      const data = await getMethod(
        endpoints.barchart(userid, "daily"),
        apikey.barchart
      );
      // console.log(data.data.body);
      clearInterval(interval);
      setBarchart(data.data.body);
      // setBarchart(mockdata);
    };
    const time = setInterval(() => {
      api();
      Daily_consumption();
    }, 2000);

    return () => {
      clearInterval(time);
      clearInterval(interval);
    };
  }, [userid]);

  return (
    <div>
      <Grid
        container
        maxWidth="calc(100% - 20px)"
        className="container_with_shadow"
        justifyContent="center"
      >
        <Grid item md={12} sm={12} lg={12} xs={12}>
          <Typography>
            <b>Consumption</b>
          </Typography>
        </Grid>

        <Grid md={4} lg={4} sm={4} sx={{ height: "230px" }}>
          <Chart
            key={threeconsuption?.historical_energy}
            options={options2.options(
              "Historic",
              threeconsuption?.historical_energy,
              "#f368e0"
            )}
            series={options2.series(75)}
            type="radialBar"
            height="220"
          />
          <Typography className="consumptionunit" variant="subtitle2">
            kWh
          </Typography>
        </Grid>

        <Grid md={4} lg={4} sm={4}>
          <Chart
            key={threeconsuption?.daily_energy}
            options={options2.options(
              "Daily",
              threeconsuption?.daily_energy,
              "#ff6b6b"
            )}
            series={options2.series((dayjs().hour() / 24) * 100)}
            type="radialBar"
            height="220"
          />
          <Typography className="consumptionunit" variant="subtitle2">
            kWh
          </Typography>
        </Grid>

        <Grid md={4} lg={4} sm={4}>
          <Chart
            key={threeconsuption?.monthly_energy}
            options={options2.options(
              "Monthly",
              threeconsuption?.monthly_energy,
              "#1dd1a1"
            )}
            series={options2.series(
              (dayjs().date() / dayjs().daysInMonth()) * 100
            )}
            type="radialBar"
            height="220"
          />
          <Typography className="consumptionunit" variant="subtitle2">
            kWh
          </Typography>
        </Grid>
      </Grid>

      <div className="container_with_shadow">
        <Typography>
          <b>Daily Consumption</b>
        </Typography>

        {/* <Bar
          option={
            optionbarchart(barchart?.timestamp ?? [], barchart?.value ?? [])
              .options
          }
          data={
            optionbarchart(barchart?.timestamp ?? [], barchart?.value ?? [])
              .data
          }
        /> */}
        <EChartsReact
          option={optionbarchart(
            barchart?.timestamp ?? [],
            barchart?.value ?? [],
            [
              "#ea5545",
              "#f46a9b",
              "#ef9b20",
              "#edbf33",
              "#ede15b",
              "#bdcf32",
              "#87bc45",
              "#27aeef",
              "#b33dc6",
            ]
          )}
        />
      </div>
    </div>
  );
}

export default ConsumptionLeft;
