import React, { useEffect, useState } from "react";
import Echarts from "echarts-for-react";
import Progress from "./top_box";
import { getMethod } from "../../api/methods";
import { apikey, endpoints } from "../../api/endpoints";
import { Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setTest } from "../../redux/dashboardslice";
import * as echarts from "echarts";

// // Chartjs
// import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import ContainerBox from "./top_box";
import dayjs from "dayjs";

// ChartJS.register(ArcElement, Tooltip, Legend);

const colors = ["#1dd1a1", "#EE6666"];

const useOption = (chartdata, selectedRow) => {
  let op = {
    color: colors,
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "cross",
        axis: "auto",
        show: false,
        label: {
          backgroundColor: "#36c1bd",
        },
      },
    },
    grid: {
      top: 20,
      bottom: 40,
    },
    xAxis: [
      {
        show: true,
        type: "category",
        // name: "Timestamp",
        nameLocation: "middle",
        nameGap: 25,
        boundaryGap: false,
        axisTick: {
          alignWithLabel: false,
        },
        axisLine: {
          onZero: false,
          lineStyle: {
            color: colors[0],
          },
        },
        axisPointer: {
          label: {
            formatter: function (params) {
              const index = chartdata?.[
                selectedRow?.devicename
              ]?.timestamp.findIndex(
                (val) => dayjs(val).format("hh:mm:ss") === params.value
              );
              // console.log();
              return (
                "Power " +
                chartdata?.[selectedRow?.devicename]?.data[index] +
                " \n" +
                params.value +
                (params.seriesData.length
                  ? "：" + params.seriesData[0].data
                  : "")
              );
            },
          },
        },
      },
      {
        // tooltip: {
        //   trigger: "axis",
        //   show: false,
        // },
        show: false,
        type: "category",
        axisTick: {
          alignWithLabel: true,
        },
        boundaryGap: false,
        axisLine: {
          onZero: false,
          lineStyle: {
            color: colors[0],
          },
        },
        axisPointer: {
          label: {
            formatter: function (params) {
              return (
                "Precipitation  " +
                params.value +
                (params.seriesData.length
                  ? ":" + params.seriesData[0].data
                  : "")
              );
            },
          },
        },
        // prettier-ignore
        data: [],
      },
    ],
    yAxis: [
      {
        splitLine: false,
        type: "value",
        name: "W",
        nameLocation: "middle",
        nameGap: 30,
        nameTextStyle: {
          fontFamily: "serif",
          fontWeight: "bold",
        },
      },
    ],
    legend: {
      show: false,
    },
    series: [
      {
        type: "line",
        xAxisIndex: 1,
        smooth: true,
        emphasis: {
          focus: "series",
        },
        tooltip: { trigger: "axis" },
        stack: "Total",
        label: { show: false },
        areaStyle: {
          opacity: 0.8,
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: colors[0],
            },
            {
              offset: 1,
              color: "#191c24",
            },
          ]),
        },
        data: [10, 12, 32, 31, 22, 12, 20, 32, 22, 12, 20, 32],
      },
    ],
  };
  const [option, setOption] = useState(op);

  useEffect(() => {
    op.series[0].data = chartdata?.[selectedRow?.devicename]?.data;
    op.xAxis[0].data = chartdata?.[selectedRow?.devicename]?.timestamp.map(
      (time) => dayjs(time).format("hh:mm:ss")
    );
    setOption(op);
  }, [chartdata, selectedRow]);

  // console.log(chartdata, selectedRow);
  return [option];
};

function LeftContainer() {
  const [chartdata, setChartData] = useState([]);
  const { selectedRow } = useSelector(
    (state) => state.dashboardreducer.mainmenu
  );
  const userid = useSelector(
    (state) => state.dashboardreducer.consumption.userid
  );
  const [option] = useOption(chartdata, selectedRow);

  // console.log(chartdata);
   const mockdata = {
    total:{timestamp:[1,2,3,4,5,6],
      data:[5,6,7,3,7]}
   }
  useEffect(() => {
    const interval = setInterval(() => {
      setChartData(mockdata);
    }, 10);
    const getapi = async () => {
      const data = await getMethod(
        endpoints.smartplugpowerchart(userid),
        apikey.smartplugpowerchart
      );
      // console.log(data);
      clearInterval(interval)
      setChartData(data.data.body);
      console.log(data.data.body);
    };
    getapi();
    return () => {
      clearInterval(interval);
    };
  }, [userid]);

  return (
    <div>
      <ContainerBox />

      <div className="container_with_shadow line_chart_mainmenu">
        <Echarts option={option} style={{ height: "230px" }} />
      </div>
    </div>
  );
}
export default LeftContainer;
