import {
  Button,
  FormControlLabel,
  Grid,
  Switch,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import LeftContainer from "./mainmenu/LeftContainer";
import RightContainer from "./mainmenu/RightContainer";
import "./mainmenu.css";
import ReactTable from "../components/Table";
import { DataGrid } from "@mui/x-data-grid";
import { height } from "@mui/system";
import LoadingSwitch from "../components/LoadingSwitch";
import { getMethod, patchMethod, postMethod } from "../api/methods";
import { apikey, endpoints } from "../api/endpoints";
import { useDispatch, useSelector } from "react-redux";
import {
  setDevicestatus,
  setSelectedRow,
  setTotaldevices,
  setDevice,
} from "../redux/dashboardslice";
import timer from "../assets/stopwatch.png";
import { makeStyles } from "@mui/styles";
import { ClickAwayListener } from "@mui/base/";
import { Await, Link } from "react-router-dom";
import SnackToast from "../components/Snackbar";
import { object } from "yup";

const columns = (setSnackbar) => [
  {
    field: "Name",
    headerName: "Name",
    minWidth: 150,
    flex: 1,
    align: "center",
    headerClassName: "datagrid_header",
    sortable: false,
    renderCell: (props) => {
      return <NameTextField {...props} setSnackbar={setSnackbar} />;
    },
  },
  {
    field: "voltage",
    headerName: "Voltage ",
    minWidth: 100,
    flex: 1,
    headerClassName: "datagrid_header",
    sortable: false,
  },
  {
    field: "current",
    headerName: "Current",
    minWidth: 100,

    flex: 1,
    headerClassName: "datagrid_header",
    sortable: false,
  },
  {
    field: "power",
    headerName: "Power",
    minWidth: 100,

    flex: 1,
    headerClassName: "datagrid_header",
    sortable: false,
  },

  {
    field: "status",
    headerName: "Status",
    minWidth: 100,
    flex: 1,
    headerClassName: "datagrid_header",
    sortable: false,
  },
  {
    field: "on/off",
    headerName: "On/Off",
    minWidth: 100,
    flex: 1,
    headerClassName: "datagrid_header",
    sortable: false,
    renderCell: (props) => {
      return <LoadingSwitch1 props={props} />;
    },
  },
  {
    field: "asd",
    headerName: "Timer",
    width: 160,
    flex: 1,
    headerClassName: "datagrid_header",
    sortable: false,
    renderCell: (props) => {
      return (
        <Link to={`/dashboard/timer/${props.id}`}>
          <img src={timer} height="25px" width="25px" />;
        </Link>
      );
    },
  },
];

const useStyles = makeStyles({
  root: {
    "&.MuiDataGrid-root .MuiDataGrid-cell:focus": {
      outline: "none",
    },
  },
});

function Mainmenu() {
  const [tabledata, setTabledata] = useState([]);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [powervoltagedata, setPowerVoltagedata] = useState({});

  // snackbar
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "",
  });

  const handleclosesnack = () => {
    setSnackbar({
      open: false,
      message: "",
      severity: "",
    });
  };

  const userid = useSelector(
    (state) => state.dashboardreducer.consumption.userid
  );
  const selectedRow = useSelector(
    (state) => state.dashboardreducer.mainmenu.selectedRow
  );

  const resetPowerVoltage = () => {
    dispatch(
      setDevice({
        total: {
          power: 0,
          voltage: 0,
          current: 0,
        },
      })
    );
    dispatch(setTotaldevices(0));
  };

  useEffect(() => {
    let interval;
    if (loading) {
      interval = setInterval(() => {
        dispatch(
          setDevice({
            total: {
              power: Math.random(),
              voltage: Math.random(),
              current: Math.random(),
            },
          })
        );
        dispatch(setTotaldevices(Math.random().toFixed(2)));
      }, 10);
    } else {
      clearInterval(interval);
      if (Object.keys(powervoltagedata).length === 0) {
        resetPowerVoltage();
      }
    }

    return () => clearInterval(interval);
  }, [loading]);

  const gettable = async () => {
    try {
      const data = await getMethod(
        endpoints.SmartPlugLatestTelemetryAPI(userid),
        apikey.SmartPlugLatestTelemetryAPI
      );
      let datarr = Object.keys(data.data.body).map((key, ind) => {
        return { devicename: key, ...data.data.body[key] };
      });
      dispatch(setTotaldevices(data.data.body.total_plugged));

      datarr = datarr.filter(
        (name) => !["total_plugged", "total"].includes(name.devicename)
      );
      setTabledata(datarr);
      dispatch(setDevice(data.data.body));
    } catch (error) {
      console.log(error);
    }
  };

  const getDevicestatus = async () => {
    try {
      const devicestatus = await getMethod(
        endpoints.deviceStatus(userid),
        apikey.deviceStatus
      );
      setLoading(false);
      setPowerVoltagedata(devicestatus.data.body);
      if (Object.keys(devicestatus.data.body).length === 0) {
        resetPowerVoltage();
      } else {
        dispatch(setDevicestatus(devicestatus.data.body));
      }
    } catch (error) {
      setLoading(false);
      resetPowerVoltage();
    }
  };

  useEffect(() => {
    setLoading(true);
    getDevicestatus();
    gettable();
    const api = setInterval(function () {
      getDevicestatus();
      gettable();
    }, 2000);

    return () => {
      clearInterval(api);
      dispatch(setSelectedRow({ devicename: "total" }));
    };
  }, [userid]);

  const rowclick = (e) => {
    dispatch(setSelectedRow({ devicename: e }));
  };

  return (
    <>
      <SnackToast
        open={snackbar.open}
        message={snackbar.message}
        severity={snackbar.severity}
        handleClose={handleclosesnack}
      />

      <Grid container>
        <Grid item lg={7} md={7} sm={12} xs={12}>
          <LeftContainer />
        </Grid>

        <Grid item lg={5} md={5} sm={12} xs={12}>
          <RightContainer />
        </Grid>

        <Grid item md={12} sm={12} xs={12}>
          <ClickAwayListener onClickAway={() => rowclick("total")}>
            <div
              className="container_with_shadow"
              style={{
                height: "200px",
              }}
            >
              <DataGrid
                sx={{ border: 0 }}
                className={classes.root}
                rows={tabledata}
                columns={columns(setSnackbar)}
                getRowId={(row) => row.devicename}
                pageSize={5}
                rowsPerPageOptions={[5]}
                selectionModel={[selectedRow.devicename]}
                onRowClick={(e) => rowclick(e.row.devicename)}
                hideFooterPagination
                hideFooterSelectedRowCount
                hideFooter
                disableColumnMenu
              />
            </div>
          </ClickAwayListener>
        </Grid>
      </Grid>
    </>
  );
}

const LoadingSwitch1 = ({ props }) => {
  const deviceStatus = useSelector(
    (state) => state.dashboardreducer.mainmenu.deviceStatus
  );

  const userid = useSelector(
    (state) => state.dashboardreducer.consumption.userid
  );
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [state, setState] = useState(
    deviceStatus[props.row.devicename]?.state == "on" ? true : false
  );

  const handleclick = async () => {
    try {
      setLoading(true);
      const data = await postMethod(
        endpoints.PlugONOFFFunction(
          props.row.devicename,
          deviceStatus[props.row.devicename]?.state == "on" ? "off" : "on"
        ),
        apikey.PlugONOFFFunction
      );

      setInterval(async () => {
        console.log("devicestatus1", data);
        const devicestatus = await getMethod(
          endpoints.deviceStatus(userid),
          apikey.deviceStatus
        );
        console.log("devicestatus", devicestatus);
        dispatch(setDevicestatus(devicestatus.data.body));
        setLoading(false);
      }, 5000);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <LoadingSwitch
      loading={loading}
      disabled={props.row.status == "unplugged"}
      onClick={handleclick}
      checked={deviceStatus[props.row.devicename]?.state == "on" ? true : false}
    />
  );
};
const NameTextField = (props) => {
  console.log(props);
  const [value, setValue] = useState(props?.row?.Name);
  const userid = useSelector(
    (state) => state.dashboardreducer.consumption.userid
  );
  const { setSnackbar } = props;
  const apicall = async () => {
    try {
      const data = await patchMethod(
        endpoints.editDeviceName,
        apikey.editDeviceName,
        { deviceid: props.id, userid: userid, deviceName: value }
      );
      console.log(data);
      setSnackbar({
        open: true,
        message: data.data.body ?? "Success",
        severity: "success",
      });
    } catch (error) {
      console.log(error);
      setSnackbar({ open: true, message: error.message, severity: "error" });
    }
  };

  return (
    <>
      <TextField
        width="30px"
        size="small"
        value={value}
        onBlur={apicall}
        onChange={(e) => {
          setValue(e.target.value);
        }}
        placeholder="name"
      />
    </>
  );
};

export default Mainmenu;
