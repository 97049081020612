import React, { useEffect, useState } from "react";
import { Bolt, DevicesOther, ElectricMeter, Power } from "@mui/icons-material";
import { TextField, Typography } from "@mui/material";
import { color } from "./color";
import { getMethod, postMethod } from "../../api/methods";
import { apikey, endpoints } from "../../api/endpoints";
import { useDispatch, useSelector } from "react-redux";
import power from "../../assets/electrical-energy.png";
// import voltage from "../../assets/high-voltage.png";
import voltage from "../../assets/voltage-indicator.png";
import devices1 from "../../assets/smart-plug.png";
import current from "../../assets/smart-plug.png";
import { setSelectedRow } from "../../redux/dashboardslice";

function PoweVoltagebox() {
  // const [powervoltagevalues, setPowerVoltage] = useState();
  const dispatch = useDispatch();
  const { userid } = useSelector((state) => state.dashboardreducer.consumption);
  const { selectedRow, totaldevices, devices } = useSelector(
    (state) => state.dashboardreducer.mainmenu
  );

  console.log("device data", devices[selectedRow?.devicename]);

  useEffect(() => {
    const getpower = async () => {
      try {
        const data = await getMethod(
          endpoints.powervoltageright(userid),
          apikey.powervoltageright
        );
        console.log(data);
      } catch (err) {
        console.log(err);
      }
    };
    const api = setInterval(() => getpower(), 20000);
    getpower();
    return () => clearInterval(api);
  }, [userid]);

  return (
    <>
      {/* Voltage power Box */}
      <div className="power_mainmenu_container_wrapper">
        <div className="power_mainmenu_container container_with_shadow">
          <div className="power_mainmenu">
            <Typography className="text_grey">
              <b>Total Power</b>
            </Typography>
            <Typography variant="h6" className="power_values">
              {Number(devices[selectedRow?.devicename]?.power ?? 0)?.toFixed(2)}{" "}
              <span className="power_units">
                <sup>W</sup>
              </span>
            </Typography>
          </div>
          {/* <div
            className="main_menu_righticon"
            style={{ backgroundColor: color[0] }}
          > */}
          {/* <Power sx={{ fontSize: "40px" }} /> */}
          <img src={power} height="40px" alt="power" />
          {/* </div> */}
        </div>

        <div className="power_mainmenu_container container_with_shadow">
          <div className="power_mainmenu">
            <Typography className="text_grey">
              <b>Total Voltage</b>
            </Typography>
            <Typography variant="h6" className="power_values">
              {Number(devices[selectedRow?.devicename]?.voltage ?? 0)?.toFixed(
                2
              )}{" "}
              <span className="power_units">
                <sup>V</sup>
              </span>
            </Typography>
          </div>
          <div className="main_menu_righticon">
            <img src={voltage} height="40px" alt="voltage" />
          </div>
        </div>

        <div className="power_mainmenu_container container_with_shadow">
          <div className="power_mainmenu">
            <Typography className="text_grey">
              <b>Total Current</b>
            </Typography>
            <Typography variant="h6" className="power_values">
              {Number(devices[selectedRow?.devicename]?.current ?? 0)?.toFixed(
                2
              )}
              <span className="power_units">
                <sup>A</sup>
              </span>
            </Typography>
          </div>
          <div
            className="main_menu_righticon"
            style={{ backgroundColor: color[2] }}
          >
            <Bolt sx={{ fontSize: "40px" }} />
          </div>
        </div>

        <div className="power_mainmenu_container container_with_shadow">
          <div className="power_mainmenu">
            <Typography className="text_grey">
              <b>Total Devices</b>
            </Typography>
            <Typography variant="h6" className="power_values">
              {totaldevices}
            </Typography>
          </div>
          <div className="main_menu_righticon">
            <img src={devices1} height="40px" alt="devices" />
          </div>
        </div>
      </div>
    </>
  );
}

export default PoweVoltagebox;
