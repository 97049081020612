import React from "react";
import { Grid, Paper, CircularProgress, Typography } from "@mui/material";
import CallMadeIcon from "@mui/icons-material/CallMade";
import CallReceivedIcon from "@mui/icons-material/CallReceived";
// import { borderRadius } from "@mui/system";
import "./top_box.css";

const pro_style = { padding: 10, borderRadius: 10, display: "flex" };

function Progress() {
  return (
    <>
      <Grid container spacing={1} paddingBottom={1}>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <div style={pro_style} className="container_with_shadow">
            <div className="main_menu_progress_container">
              <div className="box">
                <CallMadeIcon className="main_menu_top_icons" />
              </div>
            </div>
            <h3 className="text">Live</h3>
          </div>
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <div style={pro_style} className="container_with_shadow">
            <div className="main_menu_progress_container">
              <div className="box1">
                <CallReceivedIcon className="main_menu_top_icons1" />
              </div>
            </div>
            <h3 className="text">Standard</h3>
          </div>
        </Grid>
      </Grid>
    </>
  );
}
export default Progress;
