import dayjs from "dayjs"

const DayDictionary = {
  Sunday: 0,
  Monday: 1,
  Wednesday: 3,
  Friday: 5,
};

const TimeMap = {
  "automation-1": [
    {
      time: 7,
      duration: 30
    },
    {
      time: 12,
      duration: 30
    },
    {
      time: 19,
      duration: 30
    }
  ],
  "automation-2": [
    {
      time: 6,
      duration: 45
    },
    {
      time: 13,
      duration: 30
    },
    {
      time: 20,
      duration: 20
    }
  ],
  "automation-3": [
    {
      time: 9,
      duration: 30
    },
    {
      time: 11,
      duration: 30
    },
    {
      time: 21,
      duration: 30
    }
  ],
  "automation-4": [
    {
      time: 5,
      duration: 75
    },
    {
      time: 14,
      duration: 30
    },
    {
      time: 4,
      duration: 120,
    },
  ],
};

function calculateDays() {
  const weekdays = new Array(4)
    .fill(dayjs())
    .map((date, index) => date.add(index, "week").startOf("week"));

  const result = weekdays
    .flatMap((date) =>
      Object.values(DayDictionary).map((day) => date.add(day, "day")),
    )
  return result;
}

export function generateTimers(field, deviceid) {
  const days = calculateDays();
  const offTimers = TimeMap[field].flatMap(({ time }) =>
    days.map((date) => date.add(time, "hours"))
  ).filter((date) => date.isAfter(dayjs())).map((date) => ({
    device_id: deviceid,
    command: "off",
    schedule: date.utc().format("YYYY-MM-DD HH:mm:ss"),
    automation_type: field,
  }))

  const onTimers = TimeMap[field].flatMap(({ time, duration }) =>
    days.map((date) => date.add(time, "hours").add(duration + 1, "minutes"))
  ).filter((date) => date.isAfter(dayjs())).map((date) => ({
    device_id: deviceid,
    command: "on",
    schedule: date.utc().format("YYYY-MM-DD HH:mm:ss"),
    automation_type: field,
  }))

  const timers = [...offTimers, ...onTimers];
  return timers;
}
