import React, { useRef } from "react";
import {
  Grid,
  Grow,
  IconButton,
  Button,
  Slide,
  TextField,
  Typography,
  Zoom,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Dialog,
} from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import LoadingSwitch from "../../components/LoadingSwitch";
import "./timer.css";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { Stack } from "@mui/system";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import { Delete, DeleteOutline } from "@mui/icons-material";
import { DeleteMethod, getMethod } from "../../api/methods";
import { apikey, endpoints } from "../../api/endpoints";
import DialogBox from "../../components/Dialog";

function Onoff({ value, handlechange, data, deleteTimer }) {
  const [open, setOpen] = useState(false);
  const [isTimerExpired, setIsTimerExpired] = useState(false)
  const [countdown, setCountdown] = useState(dayjs.utc(value).local().diff(dayjs()));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const difference = new Date(dayjs.utc(value).local().diff(dayjs()));
      if (difference <= 0) {
        // Countdown is finished
        clearInterval(interval);
        setIsTimerExpired(true);
        return;
      }
      setCountdown(difference);
    }, 1000);

    // Clear the interval when the component unmounts or the countdown finishes
    return () => clearInterval(interval);
  }, [value]);

  // Format the countdown value
  const days = Math.floor(countdown / (1000 * 60 * 60 * 24));
  const hours = Math.floor((countdown / (1000 * 60 * 60)) % 24);
  const minutes = Math.floor((countdown / (1000 * 60)) % 60);
  const seconds = Math.floor((countdown / 1000) % 60);

  return (
    <>
      {isTimerExpired === false ?
        (<div className="onoff">
          <Typography>{dayjs.utc(value).local().format("DD/MM/YYYY hh:mm:ss a")}</Typography>
          <LoadingSwitch disabled={true} checked={data?.command == "on"} />
          <Stack direction="row" spacing={2}>
            <CountdownTimer text="Days" value={days < 0 ? 0 : days} />
            <CountdownTimer text="Hours" value={hours < 0 ? 0 : hours} />
            <CountdownTimer text="Mins" value={minutes < 0 ? 0 : minutes} />
            <CountdownTimer text="Secs" value={seconds < 0 ? 0 : seconds} />
          </Stack>{" "}
          <IconButton onClick={handleClickOpen}>
            <DeleteOutline />
          </IconButton>
          <DialogBox
            open={open}
            handleClose={handleClose}
            title={"Confirmation"}
            component={<Typography>Are you sure you want to delete ?</Typography>}
            actioncomponent={
              <>
                <Button onClick={handleClose}>No</Button>
                <Button
                  onClick={() => {
                    deleteTimer(data);
                    setOpen(false);
                  }}
                  autoFocus
                >
                  Yes
                </Button>
              </>
            }
          />
        </div>) : null}
    </>
  );
}
export default Onoff;

const CountdownTimer = ({ text, value }) => {
  return (
    <Stack alignItems="center" className="countdown_container">
      <Zoom key={value} in={true}>
        <Typography sx={{ color: "#00ffcc" }}>{value}</Typography>
      </Zoom>
      <Typography variant="caption">{text}</Typography>
    </Stack>
  );
};
