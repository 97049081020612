import { TextField, Typography } from "@mui/material";
import EChartsReact from "echarts-for-react";
import React, { useEffect } from "react";
import { color } from "./color";
import PoweVoltagebox from "./PoweVoltagebox";

function RightContainer() {
  return (
    <div>
      <PoweVoltagebox />
    </div>
  );
}

export default RightContainer;
